@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.theneColor {
  color: #e53935;
}
.color {
  color: #027bc4;
}
.bgColor {
  background-color: #e7e7e7;
}
.bgtheeColor {
  color: #e53935;
}
.textColor {
  color: #4f5252;
}

.logoheader {
  background: url("./assets/personal2.png") no-repeat right bottom #0c1a3e;
  color: #ffffff;
  min-height: 250px;
}
.list {
  list-style-type: circle;
}
.slick-prev,
.slick-next {
  width: 50px;

  height: 50px;
  font-size: 50px !important;
  background-color: black !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.slick-prev {
  left: 1% !important;
}

.slick-next {
  right: 1% !important;
}
.shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
